import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Card, CardContent, CardMedia, LinearProgress, linearProgressClasses, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { createClient } from '@supabase/supabase-js';
import Footer from '../objetos/Footer';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#e0e0e0', // Color de fondo gris claro
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: (() => {
            const numericValue = isNaN(value) ? 0 : value;
            // console.log('Valor numérico:', numericValue);
            if (numericValue < 34) return '#FF0000'; // Rojo
            if (numericValue < 70) return '#FFD700'; // Amarillo
            return '#00FF00'; // Verde
        })(),
    },
}));

// Inicializa el cliente de Supabase con la URL y el token de servicio
const supabaseClient = createClient(
    import.meta.env.VITE_SB_URL, // Usar la variable de entorno para la URL
    import.meta.env.VITE_SB_SERVICE_ROLE_KEY // Asegúrate de que este token esté disponible
);

const UsuarioPerfil = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const userContext = useUser();
    const [user, setUser] = useState(null);
    const [loggedInUserInfo, setLoggedInUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [editedUser, setEditedUser] = useState({});
    const [eventos, setEventos] = useState([]);
    const [cursosCompletados, setCursosCompletados] = useState([]);
    const [loadingCursos, setLoadingCursos] = useState(true);
    const [errorCursos, setErrorCursos] = useState(null);
    const [invitationStatus, setInvitationStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false); // Estado para el checkbox
    const [message, setMessage] = useState(''); // Estado para el mensaje
    const [formErrors, setFormErrors] = useState({}); // Estado para manejar errores de formulario

    const fetchEventos = useCallback(async () => {
        try {
            // console.log('Fetching eventos for userId:', userId);
            const { data: eventosData, error: eventosError } = await supabaseClient
                .from('eventos')
                .select('*')
                .eq('idUser', userId)
                .order('created_at', { ascending: false });

            if (eventosError) throw eventosError;

            // console.log('Eventos del usuario:', eventosData);

            const { data: listadoEventos, error: listadoError } = await supabaseClient
                .from('listado_eventos')
                .select('*');

            if (listadoError) throw listadoError;

            // console.log('Listado de eventos:', listadoEventos);

            const eventosMap = new Map(listadoEventos.map(e => [e.id, e.evento]));

            const eventosConNombres = eventosData.map(evento => ({
                ...evento,
                nombreEvento: eventosMap.get(evento.idEvento) || `Evento ID: ${evento.idEvento}`
            }));

            // console.log('Eventos con nombres:', eventosConNombres);
            setEventos(eventosConNombres);
        } catch (error) {
            console.error('Error en fetchEventos:', error);
        }
    }, [userId]);

    const fetchCursosCompletados = useCallback(async () => {
        setLoadingCursos(true);
        setErrorCursos(null);
        try {
            // console.log('Fetching cursos completados for userId:', userId);
            const { data, error } = await supabaseClient
                .from('graduados_rc09')
                .select(`
                    *,
                    cursos(idCurso, url_portada, color)
                `)
                .eq('idUser', userId);

            if (error) throw error;

            // console.log('Datos crudos de cursos completados:', data);

            if (!data || data.length === 0) {
                console.log('No se encontraron cursos completados para este usuario');
                setCursosCompletados([]);
                setLoadingCursos(false);
                return;
            }

            const cursosConDuracion = data.map(curso => {
                const inicio = new Date(curso.inicio);
                const termino = new Date(curso.termino);
                const duracionMinutos = Math.round((termino - inicio) / (1000 * 60)); // Duración en minutos
                return { ...curso, duracion: duracionMinutos };
            });

            // console.log('Cursos completados procesados:', cursosConDuracion);
            setCursosCompletados(cursosConDuracion);
        } catch (error) {
            console.error('Error al obtener los cursos completados:', error);
            setErrorCursos(error.message);
        } finally {
            setLoadingCursos(false);
        }
    }, [userId]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            const { data, error } = await supabaseClient
                .from('usuarios')
                .select('*')
                .eq('idUser', userId)
                .single();

            if (error) {
                console.error('Error al cargar los datos del usuario:', error);
            } else {
                setUser(data);
            }
            setLoading(false);
        };

        fetchUserData();
        fetchEventos();
    }, [userId, fetchEventos]);

    useEffect(() => {
        const fetchLoggedInUserInfo = async () => {
            if (userContext) {
                const { data, error } = await supabaseClient
                    .from('usuarios')
                    .select('nombre, apellido')
                    .eq('email', userContext.email)
                    .single();

                if (error) {
                    console.error('Error al cargar la información del usuario logueado:', error);
                } else {
                    setLoggedInUserInfo(data);
                }
            }
        };

        fetchLoggedInUserInfo();
    }, [userContext]);

    useEffect(() => {
        // console.log('Setting up real-time subscription for userId:', userId);
        fetchEventos();

        const channel = supabaseClient
            .channel(`eventos-changes-${userId}`)
            .on('postgres_changes', { 
                event: '*', 
                schema: 'public', 
                table: 'eventos',
                filter: `idUser=eq.${userId}`
            }, (payload) => {
                console.log('Cambio detectado en eventos:', payload);
                fetchEventos();
            })
            .subscribe((status) => {
                // console.log('Subscription status:', status);
            });

        return () => {
            // console.log('Cleaning up subscription');
            supabaseClient.removeChannel(channel);
        };
    }, [userId, fetchEventos]);

    useEffect(() => {
        fetchCursosCompletados();
    }, [fetchCursosCompletados]);

    const handleEdit = () => {
        setEditing(true);
        setEditedUser({
            nombre: user.nombre,
            apellido: user.apellido,
            email: user.email,
            fecha_inv: user.fecha_inv || '', // Agregar fecha_inv
            hora_inv: user.hora_inv || '',   // Agregar hora_inv
            lugar_inv: user.lugar_inv || ''   // Agregar lugar_inv
        });
    };

    const handleSave = async () => {
        const errors = {}; // Objeto para almacenar errores

        // Validar nombre
        const nameRegex = /^[A-Za-zÀ-ÿ\s]+$/; // Permitir solo letras y espacios
        if (!editedUser.nombre || editedUser.nombre.length < 3 || !nameRegex.test(editedUser.nombre)) {
            errors.nombre = 'El nombre debe tener al menos 3 caracteres y solo contener letras.';
        }

        // Validar apellido
        if (!editedUser.apellido || editedUser.apellido.length < 3 || /\d/.test(editedUser.apellido) || !nameRegex.test(editedUser.apellido)) {
            errors.apellido = 'El apellido debe tener al menos 3 caracteres, no puede contener números y solo debe contener letras.';
        }

        // Validar email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(editedUser.email)) {
            errors.email = 'Por favor, ingresa un email válido.';
        }

        // Validar fecha de invitación
        const fechaInv = new Date(editedUser.fecha_inv);
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00 para comparar solo la fecha

        if (editedUser.fecha_inv && fechaInv < hoy) {
            errors.fechaInv = 'La fecha de invitación no puede ser una fecha del pasado.';
        }

        // Validar campos de invitación
        const { fecha_inv, hora_inv, lugar_inv } = editedUser;
        if ((fecha_inv || hora_inv || lugar_inv) && (!fecha_inv || !hora_inv || !lugar_inv)) {
            errors.invitacion = 'Si se ingresa un campo de invitación, todos los campos deben ser completados.';
        }

        // Si hay errores, actualizar el estado y salir
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({}); // Limpiar errores si todo es válido

        const { data, error } = await supabaseClient
            .from('usuarios')
            .update({
                ...editedUser,
                fecha_inv: fechaInv.toISOString() // Asegúrate de que se guarde en formato ISO
            })
            .eq('idUser', userId);

        if (error) {
            console.error('Error al actualizar el usuario:', error);
        } else {
            setUser({ ...user, ...editedUser });
            setEditing(false);
        }
    };

    const handleChange = (e) => {
        setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
    };

    const handleSendInvitation = async () => {
        setInvitationStatus('Enviando...');
        try {
            const response = await fetch('/.netlify/functions/EmailInvitacion', {
                method: 'POST',
                body: JSON.stringify({
                    nombre: user.nombre,
                    email: user.email,
                    clave: user.clave,
                    fecha: editedUser.fecha_inv, // Agregar fecha de invitación
                    hora: editedUser.hora_inv,     // Agregar hora de invitación
                    lugar: editedUser.lugar_inv     // Agregar lugar de invitación
                })
            });

            if (response.ok) {
                const invitacionEventoId = 1;
                const { data, error } = await supabaseClient
                    .from('eventos')
                    .insert({
                        idUser: userId,
                        idEvento: invitacionEventoId
                    });

                if (error) {
                    console.error('Error al registrar el evento:', error);
                } else {
                    await fetchEventos();
                }

                setInvitationStatus('Email Enviado');
                setTimeout(() => setInvitationStatus(''), 3000);
            } else {
                throw new Error('Error al enviar la invitación');
            }
        } catch (error) {
            console.error('Error:', error);
            setInvitationStatus('Error al enviar');
            setTimeout(() => setInvitationStatus(''), 3000);
        }
    };

    const handleDeleteUser = async (userId) => {
        if (!confirmDelete) {
            setMessage('Debes confirmar que deseas eliminar al usuario.'); // Mensaje de error
            return; // No hacer nada si no se confirma
        }

        setMessage('Eliminando...'); // Cambiar el mensaje a "Eliminando..."

        try {
            // Eliminar datos de la tabla eventos
            const { error: eventosError } = await supabaseClient
                .from('eventos')
                .delete()
                .eq('idUser', userId);
            if (eventosError) throw eventosError;

            // Eliminar datos de la tabla usuarios
            const { error: userError } = await supabaseClient
                .from('usuarios')
                .delete()
                .eq('email', user.email); // Usar el email del usuario actual
            if (userError) throw userError;

            // Eliminar el usuario de auth.user
            const { error } = await supabaseClient.auth.admin.deleteUser(userId); // Eliminar el usuario de auth
            if (error) {
                console.error('Error al eliminar el usuario:', error);
            } else {
                console.log('Usuario eliminado con éxito');
                setMessage('Usuario eliminado con éxito'); // Mensaje de éxito
            }

            // Redirigir al dashboard después de un breve retraso
            setTimeout(() => {
                navigate('/dashboard'); // Cambia '/dashboard' a la ruta correcta de tu dashboard
            }, 2000); // Espera 2 segundos antes de redirigir
        } catch (error) {
            setMessage('Error al eliminar el usuario: ' + error.message); // Mensaje de error
        }
    };

    const handleCreateUser = async () => {
        setInvitationStatus('Creando...'); // Cambiar el texto a "Creando..." al iniciar el proceso
        try {
            const response = await fetch('/.netlify/functions/CrearUsuario', {
                method: 'POST',
                body: JSON.stringify({
                    // ... datos del nuevo usuario
                })
            });

            if (response.ok) {
                // Manejar la respuesta exitosa
                setInvitationStatus('Usuario creado con éxito');
            } else {
                throw new Error('Error al crear el usuario');
            }
        } catch (error) {
            console.error('Error:', error);
            setInvitationStatus('Error al crear');
        } finally {
            setTimeout(() => setInvitationStatus(''), 3000); // Restablecer el estado después de 3 segundos
        }
    };

    const filteredEventos = eventos.filter(evento =>
        evento.nombreEvento.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) return <Typography>Cargando...</Typography>;
    if (!user) return <Typography>Usuario no encontrado</Typography>;

    return (
        <div className="container">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                <Paper sx={{ p: 3, maxWidth: 400 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                        <span className="material-symbols-outlined">
                            <span className="btn-volver" onClick={() => navigate(-1)}>
                                reply
                            </span>
                        </span>
                        <Typography variant="h4" gutterBottom sx={{ ml: 2 }}>Perfil de Usuario</Typography>
                    </Box>
                    {editing ? (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="nombre"
                                label="Nombre"
                                value={editedUser.nombre}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="apellido"
                                label="Apellido"
                                value={editedUser.apellido}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="email"
                                label="Email"
                                value={editedUser.email}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="fecha_inv"
                                label="Fecha de Invitación"
                                type="date"
                                value={editedUser.fecha_inv || ''}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="hora_inv"
                                label="Hora de Invitación"
                                type="time"
                                value={editedUser.hora_inv || ''}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="lugar_inv"
                                label="Lugar de Invitación"
                                value={editedUser.lugar_inv || ''}
                                onChange={handleChange}
                            />
                            {editing && (
                                <>
                                    {formErrors.nombre && <Typography color="error">{formErrors.nombre}</Typography>}
                                    {formErrors.apellido && <Typography color="error">{formErrors.apellido}</Typography>}
                                    {formErrors.email && <Typography color="error">{formErrors.email}</Typography>}
                                    {formErrors.fechaInv && <Typography color="error">{formErrors.fechaInv}</Typography>}
                                    {formErrors.invitacion && <Typography color="error">{formErrors.invitacion}</Typography>}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Nombre:</strong></Typography>
                                    <Typography>{user.nombre}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Apellido:</strong></Typography>
                                    <Typography>{user.apellido}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Email:</strong></Typography>
                                    <Typography>{user.email}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Fecha de Invitación:</strong></Typography>
                                    <Typography>
                                        {user.fecha_inv ? new Date(user.fecha_inv + 'T00:00:00Z').toLocaleDateString('es-ES', { timeZone: 'UTC' }) : 'N/A'}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Hora de Invitación:</strong></Typography>
                                    <Typography>{user.hora_inv || 'N/A'}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Lugar de Invitación:</strong></Typography>
                                    <Typography>{user.lugar_inv || 'N/A'}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography><strong>Clave:</strong></Typography>
                                    <Typography>{user.clave}</Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                    {editing ? (
                        <>
                            <Button variant="contained" onClick={handleSave} sx={{ mt: 2, mr: 1 }}>
                                Guardar
                            </Button>
                            <Button variant="outlined" onClick={() => setEditing(false)} sx={{ mt: 2, mr: 1 }}>
                                Cancelar
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="contained" onClick={handleEdit} sx={{ mt: 2, mr: 1 }}>
                                Editar
                            </Button>
                            <Button 
                                variant="contained" 
                                onClick={handleSendInvitation} 
                                disabled={invitationStatus === 'Enviando...'}
                                sx={{ mt: 2, mr: 1, backgroundColor: '#4CAF50', '&:hover': { backgroundColor: '#45a049' } }}
                            >
                                {invitationStatus || 'Enviar Invitación'}
                            </Button>
                        </>
                    )}
                </Paper>
                <Paper sx={{ p: 3, maxWidth: 600, mt: 4, width: '100%' }}>
                    <Typography variant="h5" gutterBottom>Cursos Completados</Typography>
                    {loadingCursos ? (
                        <Typography>Cargando cursos completados...</Typography>
                    ) : errorCursos ? (
                        <Typography color="error">Error: {errorCursos}</Typography>
                    ) : cursosCompletados.length === 0 ? (
                        <Typography>No se han completado cursos aún.</Typography>
                    ) : (
                        <Stack direction="row" spacing={2} justifyContent="center" flexWrap="wrap">
                            {cursosCompletados.map((curso) => (
                                <Box key={curso.id} sx={{ flex: '0 0 auto', width: { xs: '100%', sm: '45%', md: '30%' } }}>
                                    <Card 
                                        sx={{ 
                                            height: '100%', 
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            maxWidth: 300,
                                            width: '100%',
                                            cursor: 'pointer' // Añadimos cursor pointer para indicar que es clickeable
                                        }}
                                        onClick={() => navigate(`/usuario-perfil/${userId}/RC09/${curso.intento}`, { 
                                            state: { showNavBar: true } 
                                        })} // Añadimos el evento onClick
                                    >
                                        <Typography variant="h6" gutterBottom style={{ color: curso.cursos?.color || 'black' }}>
                                            {curso.cursos?.idCurso || 'ID no disponible'}
                                        </Typography>
                                        <CardMedia
                                            component="img"
                                            sx={{ 
                                                height: 120, 
                                                objectFit: 'contain'
                                            }}
                                            image={curso.cursos?.url_portada || 'URL_de_imagen_por_defecto'}
                                            alt={`Portada del curso ${curso.curso}`}
                                        />
                                        <CardContent sx={{ flexGrow: 1, pt: 1 }}>
                                            <Typography variant="subtitle1" component="div" gutterBottom>
                                                {curso.curso || 'Nombre del curso no disponible'}
                                            </Typography>
                                            <Typography variant="body2" sx={{ mt: 0.5, textAlign: 'right' }}>
                                                {Math.floor(parseFloat((curso.porcentaje || '0').replace(',', '.')))}%
                                            </Typography>
                                            <Box sx={{ width: '100%', mb: 2 }}>
                                                <BorderLinearProgress 
                                                    variant="determinate" 
                                                    value={Math.floor(parseFloat((curso.porcentaje || '0').replace(',', '.')))}
                                                />
                                            </Box>
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'space-between', 
                                                mt: 2,
                                                flexWrap: 'wrap',
                                                gap: 1
                                            }}>
                                                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                    fecha:<br/>
                                                    {curso.termino ? new Date(curso.termino).toLocaleDateString('es-ES', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: '2-digit'
                                                    }).replace(/\//g, '-') : 'N/A'}
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                    tiempo:<br/>{curso.duracion || 'N/A'} min
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            ))}
                        </Stack>
                    )}
                </Paper>
                <Paper sx={{ p: 3, maxWidth: 600, mt: 4, width: '100%' }}>
                    <Typography variant="h5" gutterBottom>Historial de Eventos</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Buscar eventos..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Evento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredEventos.map((evento) => (
                                    <TableRow key={evento.id}>
                                        <TableCell>
                                            {new Date(evento.created_at).toLocaleString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            }).replace(/\//g, '-').replace(',', '')}
                                        </TableCell>
                                        <TableCell>{evento.nombreEvento}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Paper sx={{ p: 3, maxWidth: 600, mt: 4, width: '100%', border: '1px solid #ccc' }}>
                    <Typography variant="h5" gutterBottom>Eliminar Usuario</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirmDelete}
                                onChange={(e) => setConfirmDelete(e.target.checked)}
                            />
                        }
                        label="Por seguridad, confirma que deseas eliminar este usuario"
                    />
                    <Button 
                        variant="contained" 
                        color="error" 
                        onClick={() => handleDeleteUser(userId)} 
                        sx={{ mt: 2 }}
                        disabled={!confirmDelete} // Deshabilitar el botón si no está marcado
                    >
                        Eliminar
                    </Button>
                </Paper>
                {message && (
                    <Typography color={message.includes('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
                        {message}
                    </Typography>
                )}
            </Box>
            <Footer />
        </div>
    );
};

export default UsuarioPerfil;