import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import Divider from '../objetos/Divider';
import './RC09.css';
import Footer from '../objetos/Footer';
import { useTheme } from '@mui/material/styles';
import RC09Certificado from './RC09Certificado';
import html2pdf from 'html2pdf.js';
import { createRoot } from 'react-dom/client'; // Asegúrate de importar createRoot
import RC09Individual from './RC09Individual';
import { useNavigate } from 'react-router-dom';

  const RC09 = ({ currentUserSelected, usuario }) => {
  const minWidthPx = 350;
  const minWidthPercent = minWidthPx / window.innerWidth * 100;
  const [leftPanelWidth, setLeftPanelWidth] = useState(() => {
    const savedWidth = localStorage.getItem('leftPanelWidth');
    return savedWidth ? Math.max(parseFloat(savedWidth), minWidthPercent) : 50;
  });
  const [rightPanelWidth, setRightPanelWidth] = useState(window.innerWidth - leftPanelWidth * window.innerWidth / 100);

  const [scormUsers, setScormUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const supabaseClient = useSupabaseClient();
  const user = useUser();
  const [percentageValue, setPercentageValue] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const theme = useTheme();
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  
  // console.log('Usuario:', usuario);

  const loadScormUsers = useCallback(async () => {
    if (!user) return;

    try {
      // Obtener datos de graduados_rc09
      const { data: scormData, error: scormError } = await supabaseClient
        .from('graduados_rc09')
        .select('*')
        .order('created_at', { ascending: false });

      if (scormError) throw scormError;

      // Obtener los idUser únicos de los datos de graduados_rc09
      const userIds = scormData.map(user => user.idUser);

      // Consulta para obtener los datos de los usuarios basados en idUser
      const { data: userData, error: userError } = await supabaseClient
        .from('usuarios')
        .select('idUser, nombre, apellido')
        .in('idUser', userIds);

      if (userError) throw userError;
      // console.log('Datos de usuarios:', userData);

      // Mapeamos los datos para agregar los nombres, apellidos y empresa a los usuarios SCORM
      const mergedData = scormData.map(scormUser => {
        const userDataForScormUser = userData.find(user => user.idUser === scormUser.idUser);
        return {
          ...scormUser,
          nombre_usuario: userDataForScormUser ? userDataForScormUser.nombre : null,
          apellido_usuario: userDataForScormUser ? userDataForScormUser.apellido : null,
        };
      });

      setScormUsers(mergedData);

      if (mergedData.length > 0) {
        const firstUser = mergedData[0];
        setSelectedUser(firstUser);
        setSelectedUserId(firstUser.id);
      }
    } catch (error) {
      console.error('Error al cargar los usuarios SCORM:', error);
    }
  }, [user, supabaseClient]); // Dependencias de useCallback


  useEffect(() => {
    if (user) {
      loadScormUsers();
    }
  }, [user, loadScormUsers]);// Ahora loadScormUsers es una dependencia

  useEffect(() => {
    if (selectedUser && selectedUser.porcentaje) {
      // Elimina el símbolo de porcentaje y convierte a número flotante
      const numericPercentage = parseFloat(selectedUser.porcentaje.replace('%', ''));
      setPercentageValue(numericPercentage); // Establece el valor en el estado
    }
  }, [selectedUser]);
  
  useEffect(() => {
    // Actualizar el ancho del panel derecho
    const updateRightPanelWidth = () => {
      setRightPanelWidth(window.innerWidth - leftPanelWidth * window.innerWidth / 100);
    };
    
    window.addEventListener('resize', updateRightPanelWidth);
    updateRightPanelWidth();
    return () => window.removeEventListener('resize', updateRightPanelWidth);
  }, [leftPanelWidth]);

   const handleDrag = (newWidth) => {
     // Convertir el nuevo ancho en porcentaje del ancho de la ventana del navegador
    const widthPercent = newWidth / window.innerWidth * 100;
    // Actualizar el estado con el nuevo porcentaje, asegurándose de que no sea menor al mínimo ni mayor al máximo permitido
    setLeftPanelWidth(Math.max(minWidthPercent, Math.min(100 - minWidthPercent, widthPercent)));
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setSelectedUserId(user.id);
  };
  
    const handleInactivity = useCallback(() => {
    // console.log('Usuario inactivo, intervalo activado');
    setIsInactive(true);
  }, []);

  const resetInactivityTimer = useCallback(() => {
    // console.log('Actividad del usuario detectada, reiniciando temporizador');
    setIsInactive(false);
    clearTimeout(window.inactivityTimer);
    window.inactivityTimer = setTimeout(handleInactivity, 60 * 1000); // ajusta aquí para probar
  }, [handleInactivity]);

  useEffect(() => {
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keypress', resetInactivityTimer);

    resetInactivityTimer(); // Iniciar el temporizador de inactividad

    return () => {
      clearTimeout(window.inactivityTimer);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keypress', resetInactivityTimer);
    };
  }, [resetInactivityTimer]);
   

    useEffect(() => {
    let interval;
    if (isInactive) {
      // console.log('Intervalo de recarga de usuarios activado');
      interval = setInterval(() => {
        // console.log('Cargando usuarios...');
        loadScormUsers();
      }, 60000); // Carga los datos cada minuto si está inactivo
    } else {
      // console.log('Intervalo de recarga de usuarios desactivado');
    }

    return () => {
      if (interval) {
        // console.log('Limpieza del intervalo');
        clearInterval(interval);
      }
    };
  }, [isInactive, loadScormUsers]);
    
    const questions = [
      'Catridge de Lubricación',
      'Piola de Seguridad',
      'Polín Defectuoso'
    ];
    
    
    const logroCatridgeBienURL    = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tY2F0cmlkZ2UtZGUtbHVicmljYWNpb24uanBnIiwiaWF0IjoxNzE1NTcwNTQ3LCJleHAiOjE3NDcxMDY1NDd9.1rDymzOOchPCVS7-sXGMypGCswt2yvRTCzbv_MA13ZI&t=2024-05-13T03%3A22%3A27.827Z';
    const logroCatridgeMalURL     = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1jYXRyaWRnZS1kZS1sdWJyaWNhY2lvbi5qcGciLCJpYXQiOjE3MTU1NzA1NjIsImV4cCI6MTc0NzEwNjU2Mn0.ZnC7MpphlK9-Ayz7t_K3Tm4v_IGBcJQzOIPmd7RgHWA&t=2024-05-13T03%3A22%3A42.688Z';
    const logroProteccionBienURL  = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-barrera-de-seguridad.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tYmFycmVyYS1kZS1zZWd1cmlkYWQuanBnIiwiaWF0IjoxNzE1NTcwNTc3LCJleHAiOjE3NDcxMDY1Nzd9.jp9VwzVNYaB7Z3IjcNc995uHOACtRdOyrasMDQngXaE&t=2024-05-13T03%3A22%3A57.676Z';
    const logroProteccionMalURL   = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-barrera-de-proteccion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1iYXJyZXJhLWRlLXByb3RlY2Npb24uanBnIiwiaWF0IjoxNzE1NTcwNjI3LCJleHAiOjE3NDcxMDY2Mjd9.D6pMSpzmIZeMw-mZgzDUHhE0CX4rAs2QQ-RAXt1eDTM&t=2024-05-13T03%3A23%3A47.258Z';
    const logroPolinBienURL       = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tcG9saW4tZGVmZWN0dW9zby5qcGciLCJpYXQiOjE3MTU2MDM5MjcsImV4cCI6MTc0NzEzOTkyN30.q1jw337ryyMrehbnlqWEthpLmZk5_nG2zwjUopq80b4&t=2024-05-13T12%3A38%3A47.929Z';
    const logroPolinMalURL        = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-Polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1Qb2xpbi1kZWZlY3R1b3NvLmpwZyIsImlhdCI6MTcxNTU3MDY3NiwiZXhwIjoxNzQ3MTA2Njc2fQ.jbi4IYsvNzxrCEpVLh_D7opUBb49WBYeTHD8EuMwZAU&t=2024-05-13T03%3A24%3A36.516Z';
    


    const questionScreenshots = [
      { number: 1, correct: logroCatridgeBienURL, incorrect: logroCatridgeMalURL },
      { number: 2, correct: logroProteccionBienURL, incorrect: logroProteccionMalURL },
      { number: 3, correct: logroPolinBienURL, incorrect: logroPolinMalURL }
    ];


    const [searchQuery, setSearchQuery] = useState('');

    const formatDate = (dateString) => {
      const datePart = dateString.split('T')[0];
      const dateParts = datePart.split('-');
      return `${dateParts[2]}.${dateParts[1]}.${dateParts[0].slice(-2)}`;
    };

    // ... existing useEffect and functions

    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredScormUsers = searchQuery
    ? scormUsers.filter(user =>
        (user.email && user.email.toLowerCase().includes(searchQuery)) ||
        (user.curso && user.curso.toLowerCase().includes(searchQuery)) ||
        (user.puntaje && user.puntaje.toString().toLowerCase().includes(searchQuery))
      )
    : scormUsers;

  const pdfRef = useRef();

  const handleDownloadPDF = async () => {
    console.log('Generando PDF...');
    const element = document.createElement('div');
    const root = createRoot(element);
    
    // Asegúrate de que selectedUser tenga datos antes de pasar
    if (!selectedUser) {
      console.error('No hay usuario seleccionado');
      return; // Salir si no hay usuario seleccionado
    }

    // Renderiza el componente y pasa selectedUser y el nombre de la tabla
    root.render(
      <div style={{ backgroundColor: 'white', color: 'black', padding: '20px' }}>
        <RC09Certificado selectedUser={selectedUser} />
      </div>
    );

    console.log(element.innerHTML); // Verifica el contenido

    setTimeout(async () => {
      try {
        await html2pdf()
          .from(element)
          .save('RC09-Certificado.pdf');
      } catch (err) {
        console.error('Error al generar el PDF:', err);
      }
    }, 100);
  };

console.log(selectedUser); // Muestra toda la información de selectedUser

return (
  <div className="flex flex-col min-h-screen">

  
    <div className="split-screen">
      
      <div className={`left-pane panel h-full ${theme.palette.mode === 'dark' ? 'bg-gray-800' : 'bg-gray-200'}`} style={{ width: `${leftPanelWidth}%` }}>
        <div className="flex flex-col">

        <div className="user-info d-flex align-items-center mt-20">
        <span className="material-symbols-outlined">
        <span className="btn-volver ml-10" onClick={() => navigate(-1)}>
            reply
          </span>
        </span>
          
        </div>

           <div className="flex-3 p-10">
            <p className="text-2xl pb-3">Usuarios registrados</p>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 114-14.95A8 8 0 0110 18zm.5-8a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" clipRule="evenodd" />
                <path d="M17.707 16.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414-1.414l-2-2z" />
              </svg>
              </div>
              <input
                type="text"
                placeholder="Buscar..."
                onChange={handleSearchChange}
                className="pl-10 p-2 w-80 rounded"
              />
            </div>
          </div>

          <div className='flex w-full pl-10 table-container'>
            <table className="table w-full table-hover">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Puntaje</th>
                  <th>Fecha</th>
                  <th>Hora</th>
                </tr>
              </thead>
              <tbody>
                {filteredScormUsers.map((user, index) => (
                  <tr key={index} 
                      onClick={() => handleUserClick(user)}
                      className={selectedUserId === user.id ? 'selected-row' : ''}>
                    <td>{user.email}</td>
                    <td>{user.puntaje}</td>
                    <td>{formatDate(user.inicio)}</td>
                    <td>{user.termino.substring(11, 16)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Divider onDrag={handleDrag} />

      

      <div className="right-pane panel h-full flex flex-col" style={{ width: `${100 - leftPanelWidth}%` }}>
        {selectedUser && selectedUser.idUser && selectedUser.intento ? (
          <RC09Individual 
            userId={selectedUser.idUser} 
            intento={selectedUser.intento}
            showNavBar={false}
            isFromDashboard={true}
          />
        ) : (
          <div className="flex items-center justify-center">
            <div className="flex-1 pl-10 pt-10">
              <p className="text-3xl">Bienvenido</p>
              <br />
              <p className="text-2xl">Selecciona un usuario para ver sus resultados</p>
            </div>
          </div>
        )}
      </div>
    </div>

    <Footer />
  </div>
);

};

export default RC09;