import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { createClient } from '@supabase/supabase-js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Dashboard from './pages/Dashboard';
import RC09 from './pages/RC09';
import Home from './pages/Home';
import './index.css'
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import RC09Certificado from './pages/RC09Certificado.jsx';
import Logout from './pages/Logout';
import UsuarioPerfil from './pages/UsuarioPerfil';
import RC09Individual from './pages/RC09Individual';
import Header from './objetos/Header';
import { UserContextProvider } from './objetos/UserContext';

const supabaseUrl = import.meta.env.VITE_SB_URL
const supabaseKey = import.meta.env.VITE_SB_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

// Crear un tema de MUI
const theme = createTheme({
    palette: {
        mode: 'light', // o 'dark', según tu preferencia
    },
});

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={
              <>
                <Header />
                <Outlet />
              </>
            }>
              <Route path="/dashboard/*" element={
                <ProtectedRoute>
                  <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="RC09-Atrapamiento" element={<RC09 />} />
                    <Route path="RC09-Certificado" element={<RC09Certificado />} />
                  </Routes>
                </ProtectedRoute>
              } />
              <Route path="/usuario-perfil/:userId" element={
                <ProtectedRoute>
                  <UsuarioPerfil />
                </ProtectedRoute>
              } />
              <Route path="/usuario-perfil/:userId/RC09/:intento" element={
                <ProtectedRoute>
                  <RC09Individual isFromDashboard={false} /> {/* Agregado isFromDashboard */}
                </ProtectedRoute>
              } />
              <Route path="/salir" element={<Logout />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </UserContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabase}>
      <App />
    </SessionContextProvider>
  </React.StrictMode>
);