import React, { useState, useEffect } from 'react';
import { useUser } from '@supabase/auth-helpers-react';
import { supabase } from './../lib/supabaseClient';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import './Dashboard.css';
import TablaUsuarios from '../objetos/TablaUsuarios';
import Footer from '../objetos/Footer';
import Box from '@mui/material/Box'; // Agregado para CenteredTabs
import Tabs from '@mui/material/Tabs'; // Agregado para CenteredTabs
import Tab from '@mui/material/Tab'; // Agregado para CenteredTabs
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (() => {
      if (value < 34) return '#FF0000';
      if (value < 70) return '#FFD700';
      return '#00FF00';
    })(),
  },
}));

const Dashboard = () => {
  const userContext = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [cursos, setCursos] = useState([]);
  const [isSortedAsc, setIsSortedAsc] = useState(true); // Nuevo estado para controlar el orden
  const navigate = useNavigate(); // Inicializa useNavigate
  const [tabValue, setTabValue] = useState(0); // Estado para las pestañas
  const [cursosProgreso, setCursosProgreso] = useState({});
  const [usuariosPorCurso, setUsuariosPorCurso] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Actualiza el valor de la pestaña
  };

  useEffect(() => {
    async function fetchUserInfo() {
      if (userContext) {
        try {
          const { data, error, count } = await supabase
            .from('usuarios')
            .select('nombre, apellido', { count: 'exact' })
            .eq('email', userContext.email);

          if (error) {
            throw error;
          }

          if (count === 1) {
            setUserInfo(data[0]);
          } else {
            console.error('Error: Se encontraron múltiples usuarios con el mismo correo electrónico o ninguno.');
          }
        } catch (error) {
          console.error('Error al cargar la información del usuario:', error.message);
        }
      }
    }

    fetchUserInfo();
  }, [userContext]);

  useEffect(() => {
    async function fetchCursos() {
      try {
        const { data, error } = await supabase
          .from('cursos')
          .select('*')
          .order('idCurso', { ascending: true }); // Ordenar por defecto por idCurso ascendente
        if (error) {
          throw error;
        }
        setCursos(data);
      } catch (error) {
        console.error('Error al cargar los cursos:', error.message);
      }
    }

    fetchCursos();
  }, []);

  useEffect(() => {
    async function fetchCursosProgreso() {
      try {
        const { data, error } = await supabase
          .from('graduados_rc09')
          .select('idCurso, porcentaje, idUser')
          .order('created_at', { ascending: false });

        if (error) throw error;

        const progresoPorCurso = {};
        const usuariosPorCurso = {};

        data.forEach(registro => {
          if (!usuariosPorCurso[registro.idCurso]) {
            usuariosPorCurso[registro.idCurso] = new Set();
          }

          if (!usuariosPorCurso[registro.idCurso].has(registro.idUser)) {
            usuariosPorCurso[registro.idCurso].add(registro.idUser);
            
            if (!progresoPorCurso[registro.idCurso]) {
              progresoPorCurso[registro.idCurso] = [];
            }
            progresoPorCurso[registro.idCurso].push(parseFloat(registro.porcentaje.replace(',', '.')));
          }
        });

        const promedioProgreso = {};
        for (const [idCurso, porcentajes] of Object.entries(progresoPorCurso)) {
          const promedio = porcentajes.reduce((a, b) => a + b, 0) / porcentajes.length;
          promedioProgreso[idCurso] = Math.round(promedio);
        }

        setCursosProgreso(promedioProgreso);
        setUsuariosPorCurso(usuariosPorCurso);
      } catch (error) {
        console.error('Error al cargar el progreso de los cursos:', error.message);
      }
    }

    fetchCursosProgreso();
  }, []);

  const filteredCursos = cursos.filter(curso =>
    curso.curso.toLowerCase().includes(searchTerm.toLowerCase()) ||
    curso.idCurso.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSortByIdCurso = () => {
    const sortedCursos = [...cursos].reverse(); // Invertir el orden actual
    setCursos(sortedCursos);
    setIsSortedAsc(!isSortedAsc); // Alternar el estado de orden
  };

  return (
    <div className="container mt-10">

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          centered 
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#2E2E2E', // Cambia el color del subrayado
            },
            '& .Mui-selected': {
              color: '#2E2E2E', // Cambia el color del tab activo
            },
          }}
        >
          <Tab label="Usuarios" />
          <Tab label="Cursos" />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <TablaUsuarios />
      )}
      {tabValue === 1 && ( // Mostrar el componente UserTable en la pestaña "Usuarios"
         <>
         {/* <div className="search-container d-flex justify-content-end">
           <div className="search-bar d-flex align-items-center">
             <input 
               type="text" 
               placeholder="BUSCAR CURSO" 
               value={searchTerm} 
               onChange={(e) => setSearchTerm(e.target.value)} 
             />
             <button type="button" className="search-button">
               <span className="icon-button-order" onClick={handleSortByIdCurso}>
                 <span className="material-symbols-outlined">
                   sort_by_alpha
                 </span>
               </span>
             </button>
           </div>
         </div> */}

         <div className="tarjetas-container">
           {filteredCursos.map(curso => (
             <div className="card" key={curso.id}>
               <h2 className='idCurso' style={{ color: curso.color }}>{curso.idCurso}</h2> {/* Agregado el título que se obtiene de curso.idCurso */}
               <figure className="text-center">
                 {curso.link ? (
                   <a href={`/dashboard/${curso.link}`}><img src={curso.url_portada} alt={`Imagen del curso ${curso.curso}`} /></a>
                 ) : (
                   <img className="curso-pronto" src={curso.url_portada} alt={`Imagen del curso ${curso.curso}`} />
                 )}
               </figure>
               <div className="card-body">
                 <h3>{curso.curso}</h3>
                 <div style={{ marginTop: '30px' }}>
                   <BorderLinearProgress 
                     variant="determinate" 
                     value={cursosProgreso[curso.idCurso] || 0}
                   />
                   <p style={{ 
                     textAlign: 'center', 
                     marginTop: '10px', 
                     fontSize: '20px', 
                     fontWeight: 'bold',
                     color: (() => {
                       const value = cursosProgreso[curso.idCurso] || 0;
                       if (value < 34) return '#FF0000';
                       if (value < 70) return '#FFD700';
                       return '#00FF00';
                     })()
                   }}>
                     {cursosProgreso[curso.idCurso] || 0}%
                   </p>
                 </div>
                 <div style={{ 
                   display: 'flex', 
                   alignItems: 'center', 
                   justifyContent: 'flex-start', 
                   marginTop: '30px', 
                   paddingTop: '10px',
                   fontSize: '18px',

                 }}>
                   <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                     group
                   </span>
                   <span>{usuariosPorCurso[curso.idCurso]?.size || 0} graduados</span>
                 </div>
               </div>
             </div>
           ))}
         </div>
       </>
      )}

      <Footer />
    </div>
  );
};

export default Dashboard;
